import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  components: {
    CloseButton: {
      baseStyle: {
        _focus: {
          outline: "none",
          boxShadow: "none",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _focus: {
            boxShadow: "none",
            ring: 2,
            ringColor: "transparent",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
    Switch: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
});