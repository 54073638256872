import { Container } from "@chakra-ui/react";
import React from "react";
import MailChimpSubscribe from "react-mailchimp-subscribe";
import MaintainceForm from "./MaintanceForm";
const MaintaineceMailChimpFormSubscribe = ({ width }) => {
  const postUrl = `${process.env.REACT_APP_MAILCHIMP_URL}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
  return (
    <Container>
      <MailChimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <MaintainceForm
            width={width}
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </Container>
  );
};

export default MaintaineceMailChimpFormSubscribe;
