import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Cookies from "./components/Cookies";
import ScrollToTop from "./components/ScrollToTop";
import CategoriesPage from "./pages/Categories/Categories";
import ReactGA from "react-ga4";
import FacebookPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import Maintaince from "./pages/MaintaincePage/Maintaince";
import useContentful from "./hooks/useContentful";
import TagManager from "react-gtm-module";

// Google Analytics 4
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

// Facebook Pixel
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const Suspense = React.Suspense;
const NavBar = React.lazy(() => import("./components/NavBar"));
const Cart = React.lazy(() => import("./components/Cart/Cart"));
const Home = React.lazy(() => import("./pages/Home"));
const ProductPage = React.lazy(() => import("./pages/ProductPage"));
const NavMenu = React.lazy(() => import("./components/NavMenu"));
const Footer = React.lazy(() => import("./components/Footer"));
const FooterMobil = React.lazy(() => import("./components/FooterMobil"));
const ProductPageMobile = React.lazy(() => import("./pages/ProductPageMobile"));
const MediaQuery = React.lazy(() => import("react-responsive"));
const NavBarMobile = React.lazy(() => import("./components/NavBarMobile"));
const Webstore = React.lazy(() => import("./pages/Webstore"));
const Lookbook = React.lazy(() => import("./pages/Lookbook"));
const WebstoreMobile = React.lazy(() => import("./pages/WebstoreMobile"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const PrivacyMobile = React.lazy(() => import("./pages/PrivacyMobile"));
const LookBookDetail = React.lazy(() => import("./pages/LookBookDetail"));
const Archive = React.lazy(() => import("./pages/Archive"));
const ArchiveMobile = React.lazy(() => import("./pages/ArchiveMobile"));
const Visuals = React.lazy(() => import("./pages/Visuals"));
const Shipping = React.lazy(() => import("./pages/Shipping"));
const Imprint = React.lazy(() => import("./pages/Imprint"));
const AGB = React.lazy(() => import("./pages/AGB"));
// const CategoryPage = React.lazy(() => import("./pages/Imprint"));
const ArtworksPage = React.lazy(() => import("./pages/ArtworksPage"));
const AboutMobile = React.lazy(() => import("./pages/AboutMobile"));
const About = React.lazy(() => import("./pages/About"));
const Login = React.lazy(() => import("./pages/Login"));
FacebookPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);

TiktokPixel.init(process.env.REACT_APP_TIKTOK_PIXEL_ID);
function App() {
  const [isMaintaince, setIsMaintaince] = React.useState(
    localStorage.getItem("maintance")
  );
  const query = `{
    maintainceCollection{
      items{
        show
      }
     }
    }
  `;

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID_PUBLIC,
      ...(process.env.NODE_ENV !== "production" && {
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_PREVIEW,
      }),
    };

    TagManager.initialize(tagManagerArgs);
  }, []);
  const { data } = useContentful(query);

  useEffect(() => {
    ReactGA.event("Landed", window.location.pathname + window.location.search);
    FacebookPixel.pageView();
    // FacebookPixel.trackCustom("Landed on Website");
    TiktokPixel.pageView();
  }, []);
  const isProduction = process.env.REACT_APP_MAINTANCE === "production";

  const serverMaintaince = data?.data?.maintainceCollection?.items[0]?.show;
  const maintance = localStorage.getItem("maintance");

  useEffect(() => {
    if (serverMaintaince === true) {
      localStorage.setItem("maintance", "true");
    } else {
      localStorage.setItem("maintance", "false");
    }
  }, [serverMaintaince]);

  useEffect(() => {
    if (maintance === "true") {
      setIsMaintaince(true);
    } else {
      setIsMaintaince(false);
    }
  }, [isMaintaince, maintance]);

  return (
    <Suspense fallback={<div />}>
      <div className="App">
        <Router>
          <Cookies />
          {isProduction && isMaintaince ? (
            <Maintaince />
          ) : (
            <ScrollToTop>
              <MediaQuery minWidth={800}>
                <NavBar />
              </MediaQuery>
              <Cart />
              <NavMenu />
              <MediaQuery maxWidth={800}>
                <NavBarMobile />
              </MediaQuery>
              <Switch>
                <Route path="/artworks">
                  <ArtworksPage />
                </Route>

                <Route path="/categories/:type">
                  <CategoriesPage />
                </Route>
                <Route path="/products/:handle/:collection">
                  <MediaQuery minWidth={800}>
                    <ProductPage />
                  </MediaQuery>
                  <MediaQuery maxWidth={800}>
                    <ProductPageMobile />
                  </MediaQuery>
                </Route>
                <Route exact path="/">
                  {/* <MediaQuery minWidth={800}> */}
                  <Home />
                  {/* </MediaQuery>
                  <MediaQuery maxWidth={800}>
                    <HomeMobile />
                  </MediaQuery> */}
                </Route>
                <Route path="/webstore/">
                  <MediaQuery minWidth={800}>
                    <Webstore />
                  </MediaQuery>
                  <MediaQuery maxWidth={800}>
                    <WebstoreMobile />
                  </MediaQuery>
                </Route>

                <Route exact path="/lookbook/">
                  <Lookbook />
                </Route>
                <Route path="/lookbook/:id">
                  <LookBookDetail />
                </Route>
                <Route path="/contact/">
                  <Contact />
                </Route>
                <Route path="/login/">
                  <Login />
                </Route>
                <Route path="/privacy/">
                  <MediaQuery minWidth={800}>
                    <Privacy />
                  </MediaQuery>
                  <MediaQuery maxWidth={800}>
                    <PrivacyMobile />
                  </MediaQuery>
                </Route>
                <Route path="/imprint/">
                  <Imprint />
                </Route>
                <Route path="/agb/">
                  <AGB />
                </Route>
                <Route path="/about/">
                  <MediaQuery minWidth={800}>
                    <About />
                  </MediaQuery>
                  <MediaQuery maxWidth={800}>
                    <AboutMobile />
                  </MediaQuery>
                </Route>
                <Route path="/archive/">
                  <MediaQuery minWidth={800}>
                    <Archive />
                  </MediaQuery>
                  <MediaQuery maxWidth={800}>
                    <ArchiveMobile />
                  </MediaQuery>
                </Route>
                <Route path="/visuals/">
                  <Visuals />
                </Route>
                <Route path="/shipping/">
                  <Shipping />
                </Route>
              </Switch>
              <Cart />
              <MediaQuery minWidth={800}>
                <Footer />
              </MediaQuery>
              <NavMenu />
              <MediaQuery maxWidth={800}>
                <FooterMobil />
              </MediaQuery>
            </ScrollToTop>
          )}
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
