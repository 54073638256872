import { Stack, Text, VStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {
  CheckboxSingleControl,
  InputControl,
  SubmitButton,
} from "formik-chakra-ui";
import React from "react";
import * as yup from "yup";

const formValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter valid Email address !")
    .required("Enter Email !"),
  name: yup.string().required("Enter Your Name !"),
  isChecked: yup
    .boolean()
    .required("You must accept the terms and conditions !"),
});

const MaintainceForm = ({ status, message, onValidated, width }) => {
  return (
    <Stack align="center" justify="center" spacing={6} mb="4">
      {status !== "success" ? (
        <>
          {" "}
          <VStack spacing="0">
            <Text
              fontSize={{
                base: "lg",
                md: "xl",
              }}
              fontWeight="bold"
              textAlign="center"
              textTransform="uppercase"
            >
              {" "}
              Currently Working on New PIECES
            </Text>
            <Text
              fontSize={{
                base: "xs",
                md: "sm",
              }}
              fontWeight="semibold"
              textAlign="center"
              textTransform="uppercase"
            >
              Get on mailing list to never miss a drop again{" "}
            </Text>
          </VStack>
          <Formik
            initialValues={{ email: "", name: "", isChecked: "" }}
            validationSchema={formValidationSchema}
            onSubmit={(values) => {
              onValidated({
                EMAIL: values.email,
                MERGE1: values.name,
              });
            }}
          >
            <Form>
              <VStack spacing={6} w={width}>
                <InputControl
                  name="name"
                  borderRadius={0}
                  inputProps={{
                    placeholder: "Your Name",
                    borderRadius: "xs",
                    variant: "filled",
                    borderColor: "black",
                    borderWidth: "1px",
                  }}
                />
                <InputControl
                  name="email"
                  inputProps={{
                    placeholder: "Email Address",
                    borderRadius: "xs",
                    variant: "filled",
                    borderColor: "black",
                    borderWidth: "1px",
                  }}
                />
                <CheckboxSingleControl
                  name="isChecked"
                  checkBoxProps={{ borderColor: "black" }}
                  boxShadow="none !important"
                >
                  <Text>I agree to the Terms and Conditions</Text>
                </CheckboxSingleControl>

                <SubmitButton
                  w="full"
                  variant="outline"
                  width="full"
                  isLoading={status === "sending"}
                  _focus={{ outline: 0 }}
                  _hover={{ opacity: "90%" }}
                  mt="1rem"
                  mb="1rem"
                  height="2.8rem"
                  backgroundColor="black"
                  color="white"
                  borderRadius="0px"
                  fontSize="1rem"
                >
                  Subscribe
                </SubmitButton>
              </VStack>
            </Form>
          </Formik>
          {status === "error" && (
            <Text p="2" color="red">
              {" "}
              Something went wrong
            </Text>
          )}
        </>
      ) : (
        <Text>Thanks for subscribing</Text>
      )}
    </Stack>
  );
};

export default MaintainceForm;
