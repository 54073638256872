import {
  Box,
  Divider,
  Text,
  Checkbox,
  Stack,
  HStack,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  useMediaQuery,
  Flex,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { useCookies } from "react-cookie";

const Cookies = () => {
  const [cookies, setCookie, removeCookies] = useCookies();

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: Boolean(cookies.CookieConsent) === true ? false : true,
  });

  const settingState = useDisclosure();
  const [isMarketing, setMarketing] = useState(cookies.marketing ?? false);
  const [isStrict, setStrict] = useState(true);

  const [isAnalytics, setAnalytics] = useState(cookies.analytics ?? false);
  const [isPersonalization, setPersonalization] = useState(
    cookies.personalization ?? false
  );

  const cookieController = (name, value, setFn) => {
    if (value) {
      setCookie(name, value, { path: "/" });
      setFn(value);
    } else {
      removeCookies(name);
    }
  };

  const handleMarketing = () => {
    setMarketing(!isMarketing);
  };
  const handleAnalytics = () => {
    setAnalytics(!isAnalytics);
  };
  const handlePersonalization = () => {
    setPersonalization(!isPersonalization);
  };
  const acceptAllCookies = () => {
    cookieController("marketing", true, setMarketing);
    cookieController("analytics", true, setAnalytics);
    cookieController("personalization", true, setPersonalization);
    cookieController("strict", true, setStrict);
    cookieController("CookieConsent", true, () => {});
    onClose();
  };

  const saveSettings = () => {
    cookieController("marketing", isMarketing, setMarketing);
    cookieController("analytics", isAnalytics, setAnalytics);
    cookieController("personalization", isPersonalization, setPersonalization);
    cookieController("strict", isStrict, setStrict);
    cookieController("CookieConsent", true, () => {});
    onClose();
  };

  const checkBoxes = [
    {
      name: "strict",
      label: "STRICTLY NECESSARY (ALWAYS ACTIVE)",
      text: "Cookies required to enable basic website functionality.",
      checked: isStrict,
      onChange: () => {},
    },
    {
      name: "marketing",
      label: "MARKETING",
      text: " Cookies used to deliver advertising that is more relevant to you and your interests.",
      checked: isMarketing,
      onChange: handleMarketing,
    },
    {
      name: "personalization",
      label: "PERSONALIZATION",
      text: "Cookies allowing the website to remember choices you make (such as your username, language, or the region you are in).",
      checked: isPersonalization,
      onChange: handlePersonalization,
    },
    {
      name: "analytics",
      label: "ANALYTICS",
      text: "  Cookies helping understand how this website performs, how visitors interact with the site, and wether there may be technical issues.",
      checked: isAnalytics,
      onChange: handleAnalytics,
    },
  ];
  const [isSmallMobile] = useMediaQuery("(max-width: 450px)");
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        colorScheme="blackAlpha"
        size={isSmallMobile ? "s" : "2xl"}
        isCentered="true"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent m="1rem" borderRadius="0" color="#ccc">
          {settingState.isOpen ? (
            <>
              <ModalBody p="0" overflowY="auto">
                <Stack p="0" color="black">
                  <Divider borderColor="black" />
                  <Text py="2" px={{ base: "4", md: "8" }} fontSize="18">
                    COOKIE SETTINGS
                  </Text>
                  <Divider borderColor="black" size="8px" />
                  <Stack p={{ base: "4", md: "8" }} pt="0">
                    <Box
                      pt={{ base: "1", md: "4" }}
                      fontSize={{ base: "12px", md: "md" }}
                    >
                      By clicking "accept all cookies", you agree to the storing
                      on your device to enhance site navigation, analyze site
                      usage and assist in our marketing efforts. Please read our{" "}
                      <Link to="/privacy">Privacy Policy</Link>
                      for more information.
                    </Box>
                    <Stack spacing={5} direction="column" pt="1rem">
                      {checkBoxes.map((checkBox) => (
                        <Flex align="start" gap={2} key={checkBox.label}>
                          <Checkbox
                            size="lg"
                            colorScheme="blackAlpha"
                            display="flex"
                            alignItems="start"
                            gap={2}
                            isChecked={checkBox.checked}
                            onChange={checkBox.onChange}
                            _focus={{ outline: 0 }}
                            boxShadow="none !important"
                          />
                          <Stack mt="-1">
                            <Text fontSize={{ base: "12px", md: "md" }}>
                              {checkBox.label}
                            </Text>
                            <Text fontSize={{ base: "12px", md: "md" }}>
                              {checkBox.text}
                            </Text>
                          </Stack>
                        </Flex>
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
              </ModalBody>
              <ModalFooter
                display="flex"
                justifyContent={{ base: "center", sm: "start" }}
              >
                <HStack
                  justify="space-between"
                  flexDir={{ base: "column", sm: "row" }}
                  gap={4}
                  w="full"
                >
                  <HStack>
                    <Button
                      bg="black"
                      color="white"
                      onClick={acceptAllCookies}
                      colorScheme="blackAlpha"
                      borderRadius="0"
                      boxShadow="none !important"
                      fontSize="12px"
                    >
                      ACCEPT ALL COOKIES
                    </Button>
                    <Button
                      colorScheme="blackAlpha"
                      variant="ghost"
                      onClick={saveSettings}
                      borderRadius="0"
                      boxShadow="none !important"
                      fontSize="12px"
                    >
                      SAVE SETTINGS
                    </Button>
                  </HStack>
                </HStack>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody p="0" overflowY="auto">
                <Stack p="0" color="black">
                  <Divider borderColor="black" />
                  <Text
                    py="2"
                    px={{ base: "4", md: "8" }}
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="12px"
                  >
                    This website uses cookies
                  </Text>
                  <Divider borderColor="black" size="8px" />
                  <Stack p={{ base: "4", md: "8" }} pt="0">
                    <Text
                      fontSize="12px"
                    >
                      We use different types of cookies on our websites.
                      Essential cookies enable functions such as product selection and adding items to the shopping cart.
                      Other cookies help us to analyze and improve website usage.
                      We also use cookies for personalized advertising. See the{" "}
                      <Text as="u" href="/privacy">
                        privacy policy
                      </Text>{" "}
                      for more.
                    </Text>
                  </Stack>
                </Stack>
              </ModalBody>
              <ModalFooter
  display="flex"
  justifyContent={{ base: "center", sm: "start" }}
>
  <HStack
    justify="space-between"
    flexDir={{ base: "column", sm: "row" }}
    gap={4}
    w="full"
  >
    <HStack mx="4">
      <Button
        onClick={settingState.onOpen}
        colorScheme="blackAlpha"
        variant="ghost"
        borderRadius="0"
        boxShadow="none !important"
        fontSize="12px"
      >
        SETTINGS
      </Button>
      <Button
        bg="black"
        color="white"
        onClick={acceptAllCookies}
        colorScheme="blackAlpha"
        borderRadius="0"
        boxShadow="none !important"
        fontSize="12px"
      >
        ACCEPT
      </Button>
    </HStack>
  </HStack>
</ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
export default Cookies;
