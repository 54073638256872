import { Container, Grid } from "@chakra-ui/react";
import Lottie from "lottie-react";
import React from "react";
import loadingJson from "../loading.json";
const LoadingAnimation = () => {
  return (
    <Container
    pos="absolute"
    top="0"
    left="0"
    bg="white"
    maxW="100vw"
    zIndex="999">
      <Grid placeItems="center" h="100vh">
        <Lottie animationData={loadingJson} style={{ width: 120 }} />
      </Grid>
    </Container>
  );
};

export default LoadingAnimation;