import React, { Component } from "react";
import Client from "shopify-buy";
const ShopContext = React.createContext();

const client = Client.buildClient({
  domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY_API,
});

class ShopProvider extends Component {
  state = {
    product: {},
    products: [],
    checkout: {},
    cartProducts: [],
    isCartOpen: false,
    isMenuOpen: false,
    isLoading: false,
  };

  componentDidMount() {
    if (sessionStorage.checkout) {
      this.fetchCheckout(sessionStorage.checkout);
    } else {
      this.createCheckout();
    }
  }

  createCheckout = async () => {
    const checkout = await client.checkout.create();
    sessionStorage.setItem("checkout", checkout.id);
    this.setState({ checkout: checkout });
  };

  fetchCheckout = async (checkoutId) => {
    client.checkout.fetch(checkoutId).then((checkout) => {
      this.setState({ checkout: checkout });
    });
  };

  addItemToCheckout = async (variantId, quantity) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];
    const checkout = await client.checkout.addLineItems(
      this.state.checkout.id,
      lineItemsToAdd
    );
    this.setState({ checkout: checkout });

    this.openCart();
  };
  addExtraItemToCart = async (variantId, quantity) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];
    const checkout = await client.checkout.addLineItems(
      this.state.checkout.id,
      lineItemsToAdd
    );
    this.setState({ checkout: checkout });
  };

  removeLineItem = async (lineItemIdsToRemove) => {
    const checkout = await client.checkout.removeLineItems(
      this.state.checkout.id,
      lineItemIdsToRemove
    );
    this.setState({ checkout: checkout });
  };

  cartLineUpdate = async (lines) => {
    const checkout = await client.checkout.updateLineItems(
      this.state.checkout.id,
      lines
    );
    this.setState({ checkout: checkout });
  };

  fetchAllProducts = async () => {
    this.setState({ isLoading: true, products: [] });
    const collection = await client.collection.fetchAllWithProducts({
      productsFirst: 250,
    });

    const products = collection.map((collection) => collection.products).flat();

    this.setState({
      products: products,
      isLoading: false,
    });

    return products;
  };

  fetchProductWithHandle = async (handle) => {
    console.log(handle);
    const product = await client.product.fetchByHandle(handle);
    this.setState({ product: product });
  };

  fetchProductsByCollection = async (collectionHandle) => {
    this.setState({ isLoading: true, products: [] });
    const collection = await client.collection.fetchAllWithProducts({
      productsFirst: 250,
    });

    const products = collection
      .filter((collection) => collection.handle === collectionHandle)[0]
      .products.reverse()
      .map((product) => {
        product.collection = collectionHandle;
        return product;
      });

    this.setState({
      products: products,
      isLoading: false,
    });

    return products;
  };
  fetchCartProducts = async () => {
    const collectionHandle = "product-x";
    this.setState({ isLoading: true });
    const collection = await client.collection.fetchAllWithProducts({
      productsFirst: 250,
    });

    const products = collection
      .filter((collection) => collection.handle === collectionHandle)[0]
      .products.reverse()
      .map((product) => {
        product.collection = collectionHandle;
        return product;
      });

    this.setState({
      cartProducts: products,
      isLoading: false,
    });

    return products;
  };

  fetchProductsByCollectionHome = async (collectionHandle) => {
    const collection = await client.collection.fetchByHandle(collectionHandle);

    for (let j = 0; j < collection.products.length; j++) {
      collection.products[j].collection = collectionHandle;
    }

    return {
      products: collection.products(),
    };
  };

  closeCart = () => {
    this.setState({ isCartOpen: false });
  };

  openCart = () => {
    this.setState({ isCartOpen: true });
  };

  closeMenu = () => {
    this.setState({ isMenuOpen: false });
  };

  openMenu = () => {
    this.setState({ isMenuOpen: true });
  };

  render() {
    return (
      <ShopContext.Provider
        value={{
          ...this.state,
          fetchAllProducts: this.fetchAllProducts,
          // CheckAvailableLocation: this.CheckAvailableLocation,
          fetchCartProducts: this.fetchCartProducts,
          fetchAllProductsWithCollections: this.fetchAllProductsWithCollections,
          fetchProductsByCollectionHome: this.fetchProductsByCollectionHome,
          fetchAllCollections: this.fetchAllCollections,
          fetchProductWithHandle: this.fetchProductWithHandle,
          fetchProductsByCollection: this.fetchProductsByCollection,
          addItemToCheckout: this.addItemToCheckout,
          addExtraItemToCart: this.addExtraItemToCart,
          removeLineItem: this.removeLineItem,
          cartLineUpdate: this.cartLineUpdate,
          closeCart: this.closeCart,
          openCart: this.openCart,
          closeMenu: this.closeMenu,
          openMenu: this.openMenu,
        }}
      >
        {this.props.children}
      </ShopContext.Provider>
    );
  }
}

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

export default ShopProvider;
