import React from "react";
import MaintaineceMailChimpFormSubscribe from "./MaintanceMailChimpSubscribe";
import { Box, Center, HStack, Image } from "@chakra-ui/react";

const Maintaince = () => {
  return (
    <>
      <HStack p="4" px="8">
        <Box maxW="160px">
          <Image
            src="https://cdn.shopify.com/s/files/1/0622/7509/2701/files/recolorred_logo.png?v=1642865287"
            cursor="pointer"
          />
        </Box>
      </HStack>
      <Center h="calc(100vh - 80px)">
        <MaintaineceMailChimpFormSubscribe />
      </Center>
    </>
  );
};

export default Maintaince;
