import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ShopContext } from "../../context/shopContext";
import { Box, Grid, GridItem, Image, Skeleton } from "@chakra-ui/react";
import LoadingAnimation from "../../components/LoadingAnimation";
import useLoadingTimer from "../../hooks/useLoadingTimer";
import useContentful from "../../hooks/useContentful";
import FilterButtons from "./ui/FilterButtons/FilterButtons";
import ProductCard from "../../components/ProductCard/ProductCard";
import { useParams, useHistory } from "react-router-dom";

/* GITHUB FOLDER */

const Categories = () => {
  const { type } = useParams();

  const { fetchAllProducts, products, isLoading, fetchProductsByCollection } =
    useContext(ShopContext);

  const { animationLoading } = useLoadingTimer();

  const router = useHistory();
  const { data, isLoading: bannerLoading } = useContentful(`
    {
      categorywiseContentCollection {
        items {
          title
          imageVideo {
            url
          }
          homePageMedia{
            fileName
            url
          }
        }
      }
    }
    `);

  useEffect(() => {
    if (type === "all") {
      fetchAllProducts();
    } else {
      fetchProductsByCollection(type);
    }
  }, [type, fetchAllProducts, fetchProductsByCollection]);

  if (animationLoading) return <LoadingAnimation />;

  const bannerItems = data?.data?.categorywiseContentCollection?.items ?? [];

  const selectBannerImage = () => {
    const category = bannerItems?.filter((item) => item.title === type);

    return category[0]?.homePageMedia?.url ?? null;
  };

  return (
    <Box backgroundColor="#FFFFFF" pb="5rem">
      {bannerLoading ? (
        <Box>
          <Skeleton
            maxH={{
              base: "160px",
              md: "260px",
            }}
            w="full"
          />
        </Box>
      ) : (
        <Box
          minH={{
            base: "160px",
            md: "260px",
          }}
          overflow="hidden"
          justifyContent="center"
          display="flex"
          // padding="10px"
        >
          <Image
            w="full"
            objectFit="cover"
            src={selectBannerImage(type)}
            h={{
              base: "160px",
              md: "260px",
            }}
          />
          {/* {currentCategory === "all" ? (
            <Box
              maxH={{
                base: "160px",
                md: "260px",
              }}
              overflow="hidden"
            >
              <video
                autoPlay
                loop
                muted
                src={selectBannerImage(currentCategory)}
                style={{
                  width: "100% !important",
                  height: "260px",
                  objectFit: "cover",
                }}
              ></video>
            </Box>
          ) : (
            <Image
              w="full"
              objectFit="cover"
              src={selectBannerImage(currentCategory)}
              h={{
                base: "160px",
                md: "260px",
              }}
            />
          )} */}
        </Box>
      )}

      <FilterButtons
        selected={type}
        setSelected={(value) => {
          router.push(`/categories/${value}`);
        }}
      />

      <Grid
        templateColumns={{
          base: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
      >
        {isLoading ? (
          new Array(6).fill("").map((item, index) => (
            <GridItem
              key={item.id}
              //         borderColor="white"
              outline="2px solid white"
              borderWidth="2px"
              backgroundColor="#F5F5F5"
              minHeight="100%"
              position={"relative"}
            >
              <Skeleton
                height={{
                  base: "200px",
                  md: "400px",
                }}
                //          startColor="#F5F5F5"
              />
            </GridItem>
          ))
        ) : (
          <>
            {products
              ? products
                  ?.map((product, i) => (
                    <Link
                      to={{
                        pathname: `/products/${product.handle}/${product.collection}`,
                      }}
                      key={product.id + i}
                    >
                      <ProductCard product={product} />
                    </Link>
                  ))
                  .reverse()
              : null}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Categories;
