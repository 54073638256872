import { useEffect, useState } from "react";

const useContentful = (query, QueryVariable) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(
          `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE_ID}/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
            },

            body: JSON.stringify(
              QueryVariable
                ? {
                    query,
                    variables: {
                      [Object.keys(QueryVariable)[0]]:
                        Object.values(QueryVariable)[0],
                    },
                  }
                : { query }
            ),
          }
        );
        const json = await res.json();
        setData(json);
        setLoading(false);
      } catch (err) {
        console.log("Errrors", err);
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [query]);
  return { data, error, loading };
};

export default useContentful;
