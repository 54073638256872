import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { categories } from "../../../../constant";

const FilterButtons = ({ selected, setSelected }) => {
  return (
    <>
      <Text
        ml="2"
        textTransform="uppercase"
        fontFamily="Manrope"
        fontWeight="bold"
        fontSize={{
          base: "11px",
          md: "14px",
        }}
      >
        FILTER BY
      </Text>{" "}
      <HStack my="2" ml="2">
        {categories.map((item, index) => (
          <Button
            h={{
              base: "6",
              md: "8",
            }}
            key={index + item.value}
            border="1px solid black"
            borderWidth={{
              base: "1.5px",
              md: "2px",
            }}
            fontSize={{
              base: "11px",
              md: "14px",
            }}
            _hover={{
              bg: "black",
              color: "white",
            }}
            _active={{
              bg: "black",
              color: "white",
            }}
            borderRadius="0"
            bg={selected === item.value ? "black" : "transparent"}
            color={selected === item.value ? "white" : "black"}
            onClick={() => {
              setSelected(item.value);
            }}
            px={{
              base: "0.5rem",
              md: "2rem",
            }}
            py={{
              base: "0",
              md: "1rem",
            }}
          >
            {item.label}
          </Button>
        ))}
      </HStack>
    </>
  );
};

export default FilterButtons;
