export const convertPrice = (price) => {
  const priceString = +price;
  return priceString.toFixed(2);
};

export const calculateTotalPrice = (items) => {
  return items.reduce((total, item) => {
    return total + Math.round(item.price * 100) / 100;
  }, 0);
};
