import {
  GridItem,
  Box,
  Flex,
  Divider,
  Text,
  Image,
  Tag,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import { convertPrice } from "../../utils";

const ProductCard = ({ product, isLoading }) => {
  const soldOut = !product?.availableForSale ? true : false;
  return (
    <GridItem
      borderColor="white"
      outline="2px solid white"
      borderWidth=".5px"
      backgroundColor="#F5F5F5"
      minHeight="100%"
      position={"relative"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        w="100%"
        position={"absolute"}
        top={"0px"}
        zIndex={10}
      >
        {product?.collection === "1of1-pieces-1" ? (
          <Box>
            <Tag bg="transparent" borderRadius="0" p="2">
              <Text
                p="1"
                borderRadius="0"
                textTransform="uppercase"
                bg="#CBFFDF"
                color="black"
                
                fontSize={{
                  base: "8px",
                  md: "9px",
                }}
              >
                1OF1
              </Text>
            </Tag>
          </Box>
        ) : null}
        {soldOut && (
          <Flex pos="absolute" right="0" top="0" justify="end" w="full" p="2">
            <Text
              p="1"
              borderRadius="0"
              textTransform="uppercase"
              bg="grey"
              color="white"
              //   fontWeight="bold", vorher war fontSize 14
              fontSize={{
                base: "8px",
                md: "9px",
              }}
            >
              Sold Out
            </Text>
          </Flex>
        )}
      </Box>
      <Box align="center" overflow="hidden" pos="relative">
        <Image
          src={product?.images[0].src}
          w={{
            base: "200px",
            md: "400px",
          }}
          h={{
            base: "200px",
            md: "400px",
          }}
          zIndex={9}
          opacity={soldOut ? "0.5" : "1"}
        />
        <Image
          transition="0.05s ease-in"
          bg="#F5F5F5"
          zIndex="9"
          opacity="0"
          _hover={{ opacity: 1 }}
          pos="relative"
          top={{
            base: "-200px",
            md: "-400px",
          }}
          mb={{
            base: "-200px",
            md: "-400px",
          }}
          //   Hover Image //
          src={product?.images[1].src}
          w={{
            base: "200px",
            md: "400px",
          }}
          h={{
            base: "200px",
            md: "400px",
          }}
        />
        <Flex
          justifyContent="space-between"
          px={{
            base: 1,
            md: 2,
          }}
        >
          <Text
            fontSize={{
              base: "11px",
              md: "14px",
            }}
            textTransform="uppercase"
            fontFamily="Manrope"
            ml="2px"
            textAlign="start"
            noOfLines={1}
            pr={{ md: 4 }}
          >
            {product?.title}
          </Text>
          <Heading
            fontSize={{
              base: "11px",
              md: "14px",
            }}
            mr="5px"
            fontWeight="regular"
            textTransform="uppercase"
            fontFamily="Manrope"
          >
            {convertPrice(product?.variants[0].price.amount)}€
          </Heading>
        </Flex>
      </Box>

      <Divider
        //    borderColor="white"
        //     borderWidth="2px"
        orientation="horizontal"
        w="100%"
      />
      <Box backgroundColor="#FFFFFF"></Box>
    </GridItem>
  );
};

export default ProductCard;
