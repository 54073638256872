// create a react hook which delays the loading animation for a few seconds
import { useState, useEffect } from "react";

export default function useLoadingTimer(delay = 250) {
  const [animationLoading, setAnimationLoading] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return { animationLoading };
}